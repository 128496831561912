import './App.css';
import { Helmet } from 'react-helmet';
import FACSunderconstruction from './FACSunderconstruction2.png';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>FACS</title>
      </Helmet>
      <header className="App-header">
        <h1>Under Construction</h1>
      </header>
      <img className="image" src={FACSunderconstruction} alt='FACSunderconstruction' />
    </div>
  );
}

export default App;
